import { useRouter } from 'next/router';
import { ImageCell, Table, TableCell } from '@/lib';
import { SolidTableRow } from '@/lib';
import { ethers } from 'ethers';

export const TopCollections = ({ collections }) => {
  const router = useRouter();

  return (
    <Table
      type="solid"
      columns={[
        '#',
        'Collection',
        'Floor Price',
        '7d Volume',
        'Volume Change',
        'Items',
        'Owners',
      ]}
      grid="20px 200px 180px 180px 180px 180px 180px"
    >
      {collections.map((col, index) => (
        <SolidTableRow
          key={index}
          even={index % 2}
          onClick={() => router.push(`/collections/${col.address}`)}
          grid="20px 200px 180px 180px 180px 180px 180px"
        >
          <TableCell>
            <span>{index + 1}</span>
          </TableCell>
          <TableCell>
            <ImageCell img={col.avatar} name={col.name} />
          </TableCell>
          <TableCell>
            {col.floor_price
              ? ethers.utils.formatUnits(BigInt(col.floor_price))
              : '-'}{' '}
            FTN
          </TableCell>
          <TableCell>
            {col.volume_week
              ? Math.floor(ethers.utils.formatUnits(BigInt(col.volume_week)))
              : '-'}{' '}
            FTN
          </TableCell>
          <TableCell>
            {col.volume_day
              ? Math.floor(ethers.utils.formatUnits(BigInt(col.volume_day)))
              : '-'}{' '}
            FTN
          </TableCell>
          <TableCell>{col.items}</TableCell>
          <TableCell>{col.owners}</TableCell>
        </SolidTableRow>
      ))}
    </Table>
  );
};
