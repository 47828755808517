import React, { useEffect, useState } from 'react';
import { Countdown } from '../Countdown';
import styles from './MainCard.module.scss';
import { truncate } from '@/utils/truncate';
import cn from 'classnames';

export const MainCard = (props) => {
  const { bg, data } = props;
  const backgroundImage = bg === 'dark' ? 'none' : 'none';
  const [value, setValue] = useState(0);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    setValue(truncate(data?.price.toString(), 2, 2, 6));
  }, [data]);

  return (
    <div
      className={`${styles.card} ${bg === 'dark' ? styles.dark : styles.light}`}
      style={{
        backgroundImage,
        backgroundColor: bg === 'dark' ? 'transparent' : 'white',
        backdropFilter: 'blur(100px)',
      }}
    >
      <div className={styles.imageContainer}>
        {data?.image && (
          <img src={data?.image} className={styles.image} alt="crypto" />
        )}
      </div>
      <div
        className={cn(styles.content, {
          [styles.contentDark]: bg !== 'dark',
        })}
      >
        <div className={styles.bidInfo}>
          <p
            className={`${styles.text} ${bg === 'dark' ? styles.textDark : styles.textLight}`}
          >
            Current bid
          </p>
          <div className={styles.bidAmount}>
            <img
              src="/svg/ftn.svg"
              className={styles.cryptoIcon}
              alt="crypto"
            />
            <p className={styles.value}>{value} FTN</p>
          </div>
        </div>
        <div className={styles.auctionInfo}>
          <p
            className={`${styles.text} ${bg === 'dark' ? styles.textDark : styles.textLight}`}
          >
            Auction end
          </p>
          {isClient && (
            <div className={styles.countdown}>
              {data?.duration && (
                <Countdown timestamp={data?.duration} hourOnly />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
