import { TopCollections } from '@/components/TopCollections';
import { BannerGallery } from '@/components/BannerGallery';
import { NewDropBlock } from '@/components/NewDropBlock';
import { BlogBlock } from '@/components/BlogBlock';
import { FeedbackSlider } from '@/components/FeedbackSlider';
import {
  getDropCollection,
  getFeaturedCollections,
  getTop5Collections,
} from '@/services/API/collection';
import { getReviews } from '@/services/API/reviews';
import { getBlogArticles } from '@/services/API/blog';
import { ExploreBlock } from '@/components/ExploreBlock';
import styles from './Home.module.scss';
import { Title, Sphere } from '@/lib';
import Meta from '@/components/Meta';

const Home = ({ featured, reviews, blogs, topCollections, drop }) => {
  return (
    <div className={styles.homePage}>
      <Meta
        title="NFT Marketplace - Buy and Sell Exclusive NFTs | 8 Legends"
        description="Discover the world of exclusive NFTs on 8 Legends. Buy, sell, and trade unique digital assets with confidence. A trusted marketplace for collectors and creators. Join us and start building your NFT collection today!"
        h1="NFT Marketplace"
      />
      <div className={styles.mainContainer}>
        {featured && featured.length > 0 && <BannerGallery items={featured} />}
        <div className={styles.blocks}>
          {drop && drop.length > 0 && <NewDropBlock data={drop[0]} />}
          <div className={styles.collectionsWrapper}>
            <Title>Top Collections in 7 days</Title>
            <TopCollections collections={topCollections} />
            <Sphere className={styles.leftBanner} size="small" />
            <Sphere className={styles.rightBanner} />
          </div>
          <ExploreBlock />
          {blogs && blogs.length > 0 && <BlogBlock items={blogs} />}
          {reviews && reviews.length > 0 && <FeedbackSlider items={reviews} />}
        </div>
      </div>
    </div>
  );
};

export async function getServerSideProps() {
  const featured = await getFeaturedCollections();
  const reviews = await getReviews();
  const blogs = await getBlogArticles();
  const topCollections = await getTop5Collections();
  const drop = await getDropCollection();

  return {
    props: {
      featured,
      reviews,
      blogs,
      topCollections,
      drop,
    },
  };
}

export default Home;
