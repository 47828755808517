import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { MainCard } from '../MainCard';
import styles from './ExploreBlock.module.scss';
import { Button } from '@/lib/Button';
import { Title } from '@/lib';
import { useConnectBahamut } from '@/hooks/useConnectBahamut';

export const ExploreBlock = () => {
  const router = useRouter();
  const account = useWeb3ModalAccount();
  const { connect } = useConnectBahamut(account.chainId);

  const [nft1, nft2] = useMemo(() => {
    const now = new Date();
    const first = new Date(
      now.setDate(now.getDate() + Math.floor(Math.random() * 20)),
    );
    const seconds = new Date(
      now.setDate(now.getDate() + Math.floor(Math.random() * 20)),
    );
    first.setHours(first.getHours() + Math.floor(Math.random() * 24));
    seconds.setHours(seconds.getHours() + Math.floor(Math.random() * 24));
    return [
      {
        image: '/png/card1.webp',
        duration: first.getTime(),
        price: 1 + Number((Math.random() * 15).toFixed(2)),
      },
      {
        image: '/png/card2.webp',
        duration: seconds.getTime(),
        price: Math.floor(Math.random() * 15),
      },
    ];
  }, []);

  const handleCreateNFT = () => {
    if (account.isConnected) {
      router.push('/create-nft');
    } else {
      connect();
    }
  };

  return (
    <div className={styles.exploreBlockContainer}>
      <div className={styles.textContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>
            <Title>
              EXPLORE, COLLECT & TRADE{' '}
              <span className={styles.highlight}>NFTs</span>
            </Title>
          </div>
          <div className={styles.cardsContainerMob}>
            <div className={styles.card1}>
              <MainCard data={nft1} bg="dark" />
            </div>
            <div className={styles.card2}>
              <MainCard data={nft2} />
            </div>
          </div>
          <div className={styles.subheading}>
            <span>Publish your NFTs to power up your infinite storyline</span>
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoBlock}>
            <div className={styles.infoTitle}>COMMUNITY DRIVEN</div>
            <div className={styles.infoDescription}>
              Anyone can publish his lore as NFTs
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoTitle}>
              POWERED
              <br />
              BY AI
            </div>
            <div className={styles.infoDescription}>
              Connected
              <br />
              to Gemini
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoTitle}>IMMERSIVE CONTENT</div>
            <div className={styles.infoDescription}>
              Interact with NFTs in your storylines
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleCreateNFT}
            variant="filled"
            color="white"
            className={styles.button}
          >
            <p>Create NFT</p>
          </Button>
          <Button
            variant="filled"
            color="orange"
            onClick={() => {
              router.push('/explore');
            }}
            className={styles.button}
          >
            <p>Explore more NFTs</p>
          </Button>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        <div className={styles.card1}>
          <MainCard data={nft1} bg="dark" />
        </div>
        <div className={styles.card2}>
          <MainCard data={nft2} />
        </div>
      </div>
    </div>
  );
};
