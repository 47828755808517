import { useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { ethers } from 'ethers';
import { Flex, Icon } from '../../lib';
import styles from './BannerGallery.module.scss';
import { Button } from '@/lib/Button';
import { truncateAddress } from '@/utils/truncateAddress';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants';

export const BannerGallery = ({ items }) => {
  const [activeCollection, setActiveCollection] = useState(items[0]);
  const router = useRouter();
  const width = useWindowWidth();
  const isTablet = width && width < TABLET_RESOLUTION;

  return (
    <Flex vertical>
      <Flex vertical justify="flex-end" className={styles.banner}>
        <img
          src={activeCollection.banner ?? '/png/empty.png'}
          key={activeCollection.address}
          alt="banner"
          className={styles.bannerImg}
        />
        <div className={styles.bannerGradient}></div>
        <Flex justify="space-between" align="flex-end">
          <div className={styles.bannerInfo}>
            <h4 className={styles.bannerTitle}>{activeCollection.name}</h4>
            <div className={styles.bannerAuthor}>
              {activeCollection.avatar && (
                <img
                  src={activeCollection.avatar ?? '/png/empty.png'}
                  alt="creator"
                  className={styles.bannerAuthorImg}
                />
              )}
              <span className={styles.bannerAuthorName}>
                {truncateAddress(activeCollection.created_by_address)}
              </span>
            </div>
            <Flex gap={20} className={styles.bannerPrice}>
              <div className={styles.bannerPriceItem}>
                <span>Floor price</span>
                <div className={styles.bannerPriceItemRow}>
                  <span>
                    {activeCollection.floor_price
                      ? ethers.utils.formatUnits(
                          BigInt(activeCollection.floor_price),
                        )
                      : 0}
                  </span>
                  <img src="/svg/ftn.svg" alt="ftn" />
                </div>
              </div>
              <div className={styles.bannerPriceItem}>
                <span>7d Volume</span>
                <div className={styles.bannerPriceItemRow}>
                  <span>
                    {Math.round(
                      ethers.utils.formatUnits(
                        BigInt(activeCollection.volume_week ?? 0),
                      ),
                    )}
                  </span>
                  <img src="/svg/ftn.svg" alt="ftn" />
                </div>
              </div>
            </Flex>
          </div>
          <Button
            variant="filled"
            color="white"
            className={styles.bannerButton}
            size={isTablet ? 'small' : undefined}
            onClick={() =>
              router.push(`/collections/${activeCollection.address}`)
            }
          >
            <span>{isTablet ? 'View' : 'View collection'}</span>
            <Icon type="arrow" className={styles.arrow} />
          </Button>
        </Flex>
      </Flex>
      <Flex gap={20} className={styles.gallery}>
        {items.slice(0, 5).map((item) => (
          <div
            key={item.address}
            className={cn(styles.galleryItem, {
              [styles.galleryItemActive]:
                item.address === activeCollection.address,
            })}
            onClick={() => setActiveCollection(item)}
          >
            <img
              src={item.banner ?? '/png/empty.png'}
              alt="gallery"
              className={styles.galleryItemImg}
            />
            <div className={styles.galleryItemTitle}>{item.name}</div>
          </div>
        ))}
      </Flex>
    </Flex>
  );
};
