import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Icon } from '../../lib';
import styles from './NewDropBlock.module.scss';
import { StatusTag } from '../../lib';
import { Button } from '@/lib/Button';
import { Title } from '@/lib';
import { CardContainer } from '@/lib';
import { WaveVector } from '@/lib';
import { truncateAddress } from '@/utils/truncateAddress';
import { parseText } from '@/utils/parseText';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const images = ['/png/dropDemoTurtle.png', '/png/dropDemoCow.png'];

export const NewDropBlock = ({ className, data }) => {
  const wrapperClassName = cn(styles.wrapper, className);
  const [currentDate, setCurrentDate] = useState(0);
  const endTime = dayjs.utc(data.mint_till).tz(dayjs.tz.guess());
  const startTime = dayjs.utc(data.mint_from).tz(dayjs.tz.guess());
  const timeDifference = dayjs.duration(endTime.diff(startTime));
  const width = useWindowWidth();
  const isTablet = width && width < TABLET_RESOLUTION;
  const [mintState, setMintState] = useState('Live'); // Upcoming, Live, Ended, Loading
  const router = useRouter();

  useEffect(() => {
    if (currentDate > endTime) {
      setMintState('Ended');
    }

    if (currentDate < endTime) {
      setMintState('Upcoming');
    }

    if (currentDate > startTime && currentDate < endTime) {
      setMintState('Live');
    }
  }, [currentDate, endTime, startTime]);

  const days = timeDifference.days();
  const hours = timeDifference.hours();
  const minutes = timeDifference.minutes();
  const seconds = timeDifference.seconds();

  useEffect(() => {
    const todayDate = Math.floor(new Date().getTime() / 1000);
    setCurrentDate(todayDate);

    const interval = setInterval(
      () => setCurrentDate((prev) => prev + 1),
      1000,
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={wrapperClassName}>
      <WaveVector className={styles.bg} />
      <div className={styles.info}>
        {!isTablet && (
          <Title>
            New Drop
            <br />
            Collection
          </Title>
        )}
        <p className={styles.name}>{data.name}</p>
        <div className={styles.status}>
          <div className={styles.author}>
            <img className={styles.authorImg} src={data.avatar} alt="author" />
            <span className={styles.authorName}>
              {truncateAddress(data.created_by_address)}
            </span>
          </div>
          <StatusTag text={mintState} className={styles.statusTag} />
        </div>
        <span className={styles.desc}>{parseText(data.description)}</span>
        <div className={styles.actions}>
          {currentDate !== 0 && mintState === 'Live' && (
            <div className={styles.timer}>
              <div className={styles.timerItem}>{days}d</div>
              <div className={styles.timerItem}>{hours}h</div>
              <div className={styles.timerItem}>{minutes}m</div>
              <div className={styles.timerItem}>{seconds}s</div>
            </div>
          )}
          <Button
            variant="filled"
            color="white"
            className={styles.button}
            size="small"
            onClick={() => router.push(`/drops/${data.address}`)}
          >
            <span>View drop</span>
            <Icon type="arrow" className={styles.arrow} />
          </Button>
        </div>
      </div>
      <div className={styles.images}>
        <CardContainer className={styles.imagesTop}>
          <img src={images[0]} alt="drop" className={styles.img} />
        </CardContainer>
        <CardContainer className={styles.imagesBottom}>
          <img src={images[1]} alt="drop" className={styles.img} />
        </CardContainer>
      </div>
      {isTablet && (
        <Title>
          New Drop
          <br />
          Collection
        </Title>
      )}
    </div>
  );
};
